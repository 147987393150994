<!-- 作品详情术语分类 -->
<template>
  <div>
    <div class="actions flex jc-end mb-4">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'production:termtemplate:button:delete'"
      >删除</a-button>
      <a-button
        type="primary"
        @click="newTerms"
        v-has="'production:termtemplate:button:add'"
        icon="plus"
      >添加</a-button>
      <div style="height: 32px"></div>
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      v-has="'production:termtemplate:page:view'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickTermsRow"
      :scroll="{ x: 1000, y: 500 }"
      :pagination="ipagination"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,columnWidth: 95 }"
      @change="handleTableChange"
    >
    </a-table>
    <terms-template-modal
      :is-new="isNew"
      :productionId="parent.id"
      :show="showTermsModal"
      :id="curTempId"
      :templateList="platformTermTemplates"
      @closed="tempModalClosed"
    ></terms-template-modal>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import TermsTemplateModal from './terms-template-modal'
export default {
  components: {
    TermsTemplateModal,
  },
  mixins: [JeecgListMixin],
  props: {
    parent: {
      type: Object,
      default: {},
    },
    tab: {
      type: String,
      default: '1',
    },
  },
  watch: {
    tab(n) {
      // this.loadData();
    },
    getPlatformId() {
      this.getPlatformTermTemplates()
    },
  },
  data() {
    return {
      isNew: false,
      showTermsModal: false,
      curTempId: 0,
      selectedRowKeys: [],
      clickTermsRow: (record, index) => ({
        on: {
          click: () => {
            this.showTermsModal = true
            this.isNew = false
            this.curTempId = record.id
          },
        },
      }),
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 100,
        },
        {
          title: '分类',
          width: 150,
          dataIndex: 'templateName',
        },
        {
          title: '说明',
          dataIndex: 'description',
        },
      ],
      platformTermTemplates: [],
    }
  },
  methods: {
    tempModalClosed() {
      this.showTermsModal = false
      this.loadData()
    },
    newTerms() {
      this.isNew = true
      this.showTermsModal = true
    },
    del() {},
    getPlatformTermTemplates() {
      if (this.platformTermTemplates.length == 0 && this.parent.platformId) {
        getAction('/platform/get_templates_by_platform', { id: this.parent.platformId }).then((res) => {
          if (res.success) {
            this.platformTermTemplates = Object.assign([], res.data)
          }
        })
      }
    },
  },
  created() {
    this.getPlatformTermTemplates()
  },
  computed: {
    url: {
      get: function () {
        return {
          list: '/production/get_terms_template?id=' + this.parent.id,
          deleteBatch: '/production/delete_terms_template_batch/' + this.parent.id,
        }
      },
    },
    getPlatformId: {
      get: function () {
        return this.parent.platformId
      },
    },
  },
}
</script>

<style lang='stylus'></style>