var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:termtemplate:button:delete",
                      expression: "'production:termtemplate:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:termtemplate:button:add",
                  expression: "'production:termtemplate:button:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.newTerms },
            },
            [_vm._v("添加")]
          ),
          _c("div", { staticStyle: { height: "32px" } }),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "production:termtemplate:page:view",
            expression: "'production:termtemplate:page:view'",
          },
        ],
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickTermsRow,
          scroll: { x: 1000, y: 500 },
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 95,
          },
        },
        on: { change: _vm.handleTableChange },
      }),
      _c("terms-template-modal", {
        attrs: {
          "is-new": _vm.isNew,
          productionId: _vm.parent.id,
          show: _vm.showTermsModal,
          id: _vm.curTempId,
          templateList: _vm.platformTermTemplates,
        },
        on: { closed: _vm.tempModalClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }