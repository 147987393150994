var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4" }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("div", { staticClass: "actions flex jc-end mb-4" }, [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "align-items": "center", flex: "1" },
            },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.currentType,
                    callback: function ($$v) {
                      _vm.currentType = $$v
                    },
                    expression: "currentType",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [
                    _vm._v("术语表一览"),
                  ]),
                  _c("a-radio", { attrs: { value: 2 } }, [_vm._v("背景册")]),
                  _c("a-radio", { attrs: { value: 3 } }, [_vm._v("关系图")]),
                  _vm.checkPermission("production:term:table:view")
                    ? _c("a-radio", { attrs: { value: 4 } }, [_vm._v("表记")])
                    : _vm._e(),
                  _vm.checkPermissionNote("production:term:note:view")
                    ? _c("a-radio", { attrs: { value: 5 } }, [_vm._v("笔记")])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.currentType == 1
          ? _c("tab-terms", { attrs: { parent: _vm.parent, tab: _vm.tab } })
          : _vm.currentType == 2
          ? _c("tab-terms-back", {
              attrs: {
                userInfo: _vm.userInfo,
                parent: _vm.parent,
                isAdmin: _vm.isAdmin,
                tab: _vm.tab,
              },
            })
          : _vm.currentType == 3
          ? _c("tab-terms-echarts", {
              attrs: { parent: _vm.parent, tab: _vm.tab },
            })
          : _vm.currentType == 4
          ? _c("tab-terms-table", {
              attrs: { parent: _vm.parent, tab: _vm.tab },
            })
          : _vm.currentType == 5
          ? _c("tab-terms-note", {
              attrs: { parent: _vm.parent, tab: _vm.tab },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }