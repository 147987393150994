<!-- 作品详情术语表 -->
<template>
  <div class="py-4">
    <!-- {{ { 'parent.id': parent.id } }} -->
    <div style="position: relative">
      <div
        class="actions flex jc-end mb-4"
      >
        <div
          class="flex"
          style="align-items: center; flex: 1"
        >
          <a-radio-group v-model="currentType">
            <a-radio :value="1">术语表一览</a-radio>
            <a-radio :value="2">背景册</a-radio>
            <a-radio :value="3">关系图</a-radio>
            <a-radio :value="4" v-if='checkPermission("production:term:table:view")' >表记</a-radio>
            <a-radio :value="5" v-if='checkPermissionNote("production:term:note:view")'>笔记</a-radio>
          </a-radio-group>
        </div>
      </div>
      <tab-terms
        :parent="parent"
        :tab="tab"
        v-if="currentType == 1"
      ></tab-terms>
      <!-- <tab-terms-template
        :parent="parent"
        :tab="tab"
        v-else-if="currentType == 2"
      ></tab-terms-template> -->
      <tab-terms-back
        :userInfo='userInfo'
        :parent="parent"
        :isAdmin='isAdmin'
        :tab="tab"
        v-else-if="currentType == 2"
      ></tab-terms-back>
      <tab-terms-echarts
        :parent="parent"
        :tab="tab"
        v-else-if="currentType == 3"
      ></tab-terms-echarts>
      <tab-terms-table
        :parent="parent"
        :tab="tab"
        v-else-if="currentType == 4"
      ></tab-terms-table>
      <tab-terms-note
        :parent="parent"
        :tab="tab"
        v-else-if="currentType == 5"
      ></tab-terms-note>
    </div>
  </div>
</template>

<script>
import TabTerms from './tab-terms.vue'
import tabTermsTable from './tab-terms-table.vue'
import tabTermsNote from './tab-terms-note.vue'
import TabTermsTemplate from './tab-terms-template.vue'
import TabTermsBack from './tab-terms-back.vue'
import TabTermsEcharts from './tab-terms-echarts.vue'
import { checkPermission } from '@/utils/hasPermission'
export default {
  components: {
    TabTerms,
    tabTermsTable,
    tabTermsNote,
    TabTermsTemplate,
    TabTermsEcharts,
    TabTermsBack
  },
  props: {
    parent: {
      type: Object,
      default: {},
    },
    userInfo: {
      type: Object,
      default: ()=>({}),
    },
    tab: {
      type: String,
      default: '1',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentType: 1, //当前选择的类型，1表示术语表，2表示模板
    }
  },
  mounted(){
    this.currentType=this.$route.query.currentType?this.$route.query.currentType-0:1
  },
  methods:{
    checkPermission(str) {
      return checkPermission(str)&&this.parent.departName.indexOf('韩语部')==-1
    },
    checkPermissionNote(str) {
      return checkPermission(str)
    },
  }
}
</script>
